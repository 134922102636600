import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PageTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <main className="container max-w-4xl px-4 flex-grow">
        <article itemScope itemType="http://schema.org/Article">
          <header className="mb-4">
            {/* title */}
            <h1
              itemProp="headline"
              className="font-bold text-3xl lg:text-4xl pb-1"
            >
              {post.frontmatter.title}
            </h1>

            {/* description */}
            {post.frontmatter.description && (
              <p className="text-xl lg:text-2xl pb-1">
                {post.frontmatter.description}
              </p>
            )}

            {/* date stuff */}
            <time dateTime={post.frontmatter.created}>
              <p className="text-base pt-1">
                {post.frontmatter.date_created}
                {post.frontmatter.created !== post.frontmatter.updated &&
                  ` (Updated: ${post.frontmatter.date_updated})`}
              </p>
            </time>
          </header>

          {/* content section */}
          <section
            className="content mt-8"
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
        </article>
      </main>

    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        date_created: created(formatString: "LL")
        date_updated: updated(formatString: "LL")
        created
        updated
      }
    }
  }
`
